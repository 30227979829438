<mat-card>
  <mat-card-header class="tw-items-center">
    <div>
      <mat-card-title class="tw-flex tw-items-center"
        >{{ publicUser().email }}
        <svg-icon
          class="tw-ml-3"
          [ngClass]="{ 'text-green': publicUser().confirmed, 'text-red': !publicUser().confirmed }"
          [key]="publicUser() | confStateIcon"
          [matTooltip]="publicUser() | confStateTooltip"
      /></mat-card-title>
      <mat-card-subtitle>{{ 'common.created_at' | translate }}: {{ publicUser().created_at | date }}</mat-card-subtitle>
    </div>
    @if (!readonly()) {
      <app-toolbar-menu class="tw-ml-auto">
        @if (publicUser().confirmed) {
          <button
            (click)="onPasswordReset()"
            mat-menu-item>
            <div class="tw-flex">
              <fa-stack
                class="tw-self-center"
                size="2xs">
                <fa-icon
                  icon="rotate-left"
                  stackItemSize="2x"></fa-icon>
                <fa-icon
                  icon="lock"
                  stackItemSize="1x"></fa-icon>
              </fa-stack>
              <span class="tw-ml-3">{{ 'user.reset_password' | translate }}</span>
            </div>
          </button>
        } @else {
          <button
            (click)="onResendInvitation()"
            mat-menu-item>
            <div class="tw-flex">
              <svg-icon
                class="tw-flex"
                key="user-email-resend" />
              <span class="tw-ml-3">{{ 'user.resend_invitation' | translate }}</span>
            </div>
          </button>
        }
      </app-toolbar-menu>
    }
  </mat-card-header>
  <mat-card-content>
    <div class="tw-flex tw-gap-6 tw-pt-3">
      @if (assignedCustomers$ | async; as assignedCustomers) {
        @if (assignedCustomers.length) {
          <mat-card
            class="tw-w-1/2"
            appearance="outlined">
            <mat-card-header
              ><mat-card-title class="tw-pl-4">{{ 'common.customers' | translate }}</mat-card-title></mat-card-header
            >
            <mat-card-content>
              <mat-list>
                @for (item of assignedCustomers; track $index; let last = $last) {
                  <mat-list-item
                    [ngClass]="{ 'hover:tw-bg-[#f5f4f4]': !readonly(), 'tw-cursor-pointer': !readonly() }"
                    (click)="onNavigate('/customers/' + item.id)">
                    <div class="tw-flex tw-items-center">
                      <span>[{{ item.number }}] - {{ item.name }}</span>
                      @if (!readonly()) {
                        <button
                          class="tw-ml-auto"
                          [matTooltip]="'common.delete' | translate"
                          (click)="onDelete(item.id, 'customer'); $event.stopPropagation()"
                          mat-icon-button>
                          <fa-icon icon="trash"></fa-icon>
                        </button>
                      }
                    </div>
                  </mat-list-item>
                  @if (!last) {
                    <mat-divider></mat-divider>
                  }
                }
              </mat-list>
            </mat-card-content>
          </mat-card>
        }
      }

      @if (assignedMultipliers$ | async; as assignedMultipliers) {
        @if (assignedMultipliers.length) {
          <mat-card
            class="tw-w-1/2"
            appearance="outlined">
            <mat-card-header
              ><mat-card-title class="tw-pl-4">{{ 'common.multis' | translate }}</mat-card-title></mat-card-header
            >
            <mat-card-content>
              <mat-list>
                @for (item of assignedMultipliers; track $index; let last = $last) {
                  <mat-list-item
                    [ngClass]="{ 'hover:tw-bg-[#f5f4f4]': !readonly(), 'tw-cursor-pointer': !readonly() }"
                    (click)="onNavigate('/multipliers/' + item.id)"
                    ><div class="tw-flex tw-items-center">
                      <span>[{{ item.number }}] - {{ item.name }} </span>
                      @if (!readonly()) {
                        <button
                          class="tw-ml-auto"
                          [matTooltip]="'common.delete' | translate"
                          (click)="onDelete(item.id, 'multiplier'); $event.stopPropagation()"
                          mat-icon-button>
                          <fa-icon icon="trash"></fa-icon>
                        </button>
                      }
                    </div>
                  </mat-list-item>
                  @if (!last) {
                    <mat-divider></mat-divider>
                  }
                }
              </mat-list>
            </mat-card-content>
          </mat-card>
        }
      }
    </div>
  </mat-card-content>
</mat-card>
