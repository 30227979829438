import { AbstractControl, Validators } from '@angular/forms';
import { CsvTemplate, CsvTemplateFormControl, CsvTemplateFormGroup } from '../models/csv-template';
import { checkDuplicateTokens, replaceWhiteSpace } from './co-template-validators';
import { CsvTemplateBuilder } from './csv-template-builder';

export class LoCsvTemplateBuilder extends CsvTemplateBuilder {
  static fromTemplate(template: CsvTemplate) {
    return new this(template.type)
      .setDescription(template.description)
      .setLabel(template.label)
      .setExampleData(template.exampleData)
      .setAdditionalFields(template.additionalFields);
  }

  getForm(): CsvTemplateFormGroup {
    return new CsvTemplateFormGroup<{ [key: string]: AbstractControl<any, any> }>({
      index: new CsvTemplateFormControl({}),
      token: new CsvTemplateFormControl({
        label: 'Token',
        validatorOrOpts: [Validators.required, checkDuplicateTokens(this.validationData)],
        format: replaceWhiteSpace,
      }),
      amount: new CsvTemplateFormControl({
        label: 'Betrag',
        validatorOrOpts: [Validators.required, Validators.pattern(/^\d+,\d+$/)],
      }),
    });
  }
}
