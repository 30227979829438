import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { URITemplate } from '@app/api/services/uri-template';
import { fieldValueToCents } from '@app/shared';
import { ApiResponse, LoadOrder, ProductCategoryType } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { TranslateService } from '@ngx-translate/core';
import moment, { MomentInput } from 'moment';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { CsvUploadPreviewDialogComponent } from '../../csv-upload-preview-dialog/csv-upload-preview-dialog.component';
import { PreviewDialogData } from '../../models/preview-dialog-data';

@Injectable({
  providedIn: 'root',
})
export class LoCsvUploadService {
  collectionUri = new URITemplate('{+api_v1_base}/load_orders');
  httpClient = inject(HttpClient);
  private matDialog = inject(MatDialog);
  private router = inject(Router);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  postLoadOrder(data: {
    due_date?: string;
    customer_id: string;
    voucher_category: ProductCategoryType;
    entries_data: {
      amount: {
        cents: number;
        currency: string;
      };
    }[];
  }): Observable<LoadOrder> {
    return this.httpClient
      .post<ApiResponse<LoadOrder>>(this.collectionUri.build(), data)
      .pipe(map((response) => response.data));
  }

  openDialog(
    previewData: PreviewDialogData,
    customer_id?: string,
    due_date?: MomentInput,
    product?: ProductCategoryType
  ) {
    return this.matDialog
      .open(CsvUploadPreviewDialogComponent, {
        width: '90%',
        data: previewData,
      })
      .afterClosed()
      .pipe(
        switchMap((formArray: any[]) => {
          if (formArray && customer_id && product) {
            return this.postLoadOrder({
              due_date: due_date ? moment(due_date).format('YYYY-MM-DD') : undefined,
              customer_id,
              voucher_category: product,
              entries_data: formArray.map((row) => ({
                ...row,
                amount: {
                  cents: fieldValueToCents(row.amount as string),
                  currency: 'EUR',
                },
              })),
            }).pipe(
              tap({
                next: (order) => {
                  this.router.navigate(['/load-orders', order.id]);
                },
                error: (error: Error) => {
                  this.notification.open({
                    message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
                  });
                },
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }
}
